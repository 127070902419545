.asher__tap__to__continue {
  position: absolute;
  bottom: 20vh;
  right: 5%;
  color: #ffff;
  font-weight: bold;
  font-size: 2rem;
  cursor: pointer;
}

.asher__intro__logo__container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  .play__logo {
    width: 20%;
  }
}
// .helper__text__container__main {
//   position: absolute;
//   top: 10%;
//   width: 100%;
//   display: flex;
//   justify-content: space-between;
//   z-index: 30;
//   .helper__text__one__container {
//     margin-left: 10rem;
//     margin-top: 5rem;
//     .arrow__one {
//       transform: rotate(90deg);
//       opacity: 1;
//       animation: fadeIn 3s;
//     }

//     .text__one {
//       padding: 2rem 0;
//       text-align: center;
//       vertical-align: middle;
//       background-color: #ffff;
//       color: black;
//       font-size: 2.2rem;
//       font-weight: bold;
//       animation: textAnim 3s;
//     }
//   }
//   .helper__text__two__container {
//     margin-left: -35%;
//     margin-top: -1%;
//     .arrow__two {
//       transform: scaleX(-1) rotate(110deg);
//       opacity: 1;
//       animation: fadeIn 3s;
//     }
//     .text__two {
//       margin-top: 3rem;
//       margin-left: 1.9rem;
//       padding: 2rem 0;
//       text-align: center;
//       vertical-align: middle;
//       background-color: #ffff;
//       color: black;
//       font-size: 2.2rem;
//       font-weight: bold;
//       position: relative;
//       top: -20%;
//       left: 55%;
//       animation: textAnim 3s;
//     }
//   }

//   .helper__text__three__container {
//     z-index: 1;

//     position: relative;
//     right: 12%;
//     .arrow__three {
//       position: relative;
//       top: -15%;
//       transform: scaleX(-1) rotate(70deg);
//       opacity: 1;
//       animation: fadeIn 3s;
//     }
//     .text__three {
//       padding: 2rem 0;
//       text-align: center;
//       vertical-align: middle;
//       background-color: #ffff;
//       color: black;
//       font-size: 2.2rem;
//       font-weight: bold;
//       position: relative;
//       top: -5rem;
//       animation: textAnim 3s;
//     }
//   }
// }

// .tap__to__continue {
//   position: absolute;
//   bottom: 10vh;
//   right: 15%;
//   color: #ffff;
//   font-weight: bold;
//   font-size: 2rem;
//   cursor: pointer;
//   z-index: 9999;
// }

// @keyframes fadeIn {
//   0% {
//     opacity: 0;
//   }

//   15% {
//     opacity: 0;
//   }

//   100% {
//     opacity: 1;
//   }
// }

// @keyframes textAnim {
//   0% {
//     opacity: 0;
//   }
//   30% {
//     opacity: 0;
//   }

//   100% {
//     opacity: 1;
//   }
// }

// @media screen and (max-device-width: 1600px) and (orientation: landscape) {
//   .helper__text__container__main {
//     // left: -47px;
//     .helper__text__one__container {
//       margin-left: 10rem;
//       margin-top: 0.5rem;
//       .arrow__one {
//         transform: rotate(90deg);
//         width: 15rem;
//       }

//       .text__one {
//         padding: 1.6rem 0;
//         font-size: 2rem;
//       }
//     }
//     .helper__text__two__container {
//       margin-left: -35%;
//       margin-top: -1%;
//       .arrow__two {
//         transform: scaleX(-1) rotate(110deg);
//         width: 15rem;
//       }
//       .text__two {
//         padding: 1.6rem 1.5rem;
//         font-size: 2rem;
//         top: -20%;
//         left: 55%;
//       }
//     }

//     .helper__text__three__container {
//       right: 12%;
//       top: 46px;
//       left: 10px;
//       .arrow__three {
//         top: -25%;
//         left: -25%;
//         transform: scaleX(-1) rotate(70deg);
//         width: 15rem;
//       }
//       .text__three {
//         padding: 1.6rem 0;
//         font-size: 2rem;
//         top: -5rem;
//       }
//     }
//   }
// }

// @media only screen and (min-device-width: 1025px) and (max-device-width: 1440px) and (orientation: landscape) {
//   .helper__text__container__main {
//     top: 13%;
//     .helper__text__one__container {
//       margin-left: 10rem;
//       margin-top: 0.5rem;
//       .arrow__one {
//         transform: rotate(90deg);
//         width: 15rem;
//       }

//       .text__one {
//         padding: 1.6rem 0;
//         font-size: 2rem;
//       }
//     }
//     .helper__text__two__container {
//       margin-left: -35%;
//       margin-top: -1%;
//       .arrow__two {
//         transform: scaleX(-1) rotate(110deg);
//         width: 15rem;
//       }
//       .text__two {
//         padding: 1.6rem 1.6rem;
//         font-size: 2rem;
//         top: -20%;
//         left: 55%;
//       }
//     }

//     .helper__text__three__container {
//       right: 12%;
//       .arrow__three {
//         left: -12%;
//         top: -15%;
//         transform: scaleX(-1) rotate(70deg);
//         width: 15rem;
//       }
//       .text__three {
//         padding: 1.6rem 0;
//         font-size: 2rem;
//         top: -5rem;
//       }
//     }
//   }
// }

// @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
//   .helper__text__container__main {
//     top: 11%;
//     .helper__text__one__container {
//       margin-left: 7rem;
//       margin-top: 0.5rem;
//       .arrow__one {
//         transform: rotate(90deg);
//         width: 15rem;
//       }

//       .text__one {
//         padding: 1.6rem 0;
//         font-size: 1.8rem;
//       }
//     }
//     .helper__text__two__container {
//       margin-left: -15%;
//       margin-top: -1%;
//       .arrow__two {
//         transform: scaleX(-1) rotate(110deg);
//         width: 15rem;
//       }
//       .text__two {
//         padding: 1.6rem 1.6rem;
//         font-size: 1.8rem;
//         top: -20%;
//         left: 55%;
//       }
//     }

//     .helper__text__three__container {
//       right: 12%;
//       margin-right: 55px;

//       .arrow__three {
//         top: -15%;
//         transform: scaleX(-1) rotate(100deg);
//         width: 15rem;
//       }
//       .text__three {
//         padding: 1.6rem 0;
//         font-size: 1.8rem;
//         top: -5rem;
//       }
//     }
//   }
// }

// @media only screen and (min-device-width: 576px) and (max-device-width: 767px) and (orientation: landscape) {
//   .helper__text__container__main {
//     top: 15%;
//     .helper__text__one__container {
//       margin-left: 6rem;
//       margin-top: 0.5rem;
//       .arrow__one {
//         transform: rotate(90deg);
//         width: 10rem;
//       }

//       .text__one {
//         padding: 1.2rem 0;
//         font-size: 1.6rem;
//       }
//     }
//     .helper__text__two__container {
//       margin-left: -35%;
//       margin-top: -1%;
//       .arrow__two {
//         transform: scaleX(-1) rotate(110deg);
//         width: 10rem;
//       }
//       .text__two {
//         padding: 1.2rem 1.2rem;
//         font-size: 1.6rem;
//         top: -20%;
//         left: 35%;
//       }
//     }

//     .helper__text__three__container {
//       right: 12%;
//       margin-right: 60px;
//       .arrow__three {
//         top: -28%;
//         transform: scaleX(-1) rotate(100deg);
//         width: 10rem;
//       }
//       .text__three {
//         padding: 1.2rem 0;
//         font-size: 1.6rem;
//         top: -5rem;
//       }
//     }
//   }
// }
// @media only screen and  (max-device-width: 533px) and (orientation: landscape) {
//   .helper__text__container__main {
//     top: 15%;
//     .helper__text__one__container {
//       margin-left: 6rem;
//       margin-top: 0.5rem;
//       .arrow__one {
//         transform: rotate(90deg);
//         width: 10rem;
//       }

//       .text__one {
//         padding: 1.2rem 0;
//         font-size: 1.6rem;
//       }
//     }
//     .helper__text__two__container {
//       margin-left: -35%;
//       margin-top: -1%;
//       .arrow__two {
//         transform: scaleX(-1) rotate(110deg);
//         width: 10rem;
//       }
//       .text__two {
//         padding: 1.2rem 1.2rem;
//         font-size: 1.6rem;
//         top: -20%;
//         left: 35%;
//       }
//     }

//     .helper__text__three__container {
//       right: 12%;
//       .arrow__three {
//         top: -15%;
//         transform: scaleX(-1) rotate(100deg);
//         width: 10rem;
//       }
//       .text__three {
//         padding: 1.2rem 0;
//         font-size: 1.6rem;
//         top: -5rem;
//       }
//     }
//   }
// }
// @media screen and (min-device-width: 1201px) and (max-device-width: 1599px) {
//   .helper__text__container__main {
//     left: -47px;
//     width: 95%;
//     .helper__text__one__container {
//       margin-left: 10rem;
//       margin-top: 0.5rem;
//       .arrow__one {
//         transform: rotate(90deg);
//         width: 15rem;
//       }

//       .text__one {
//         padding: 1.6rem 0;
//         font-size: 2rem;
//       }
//     }
//     .helper__text__two__container {
//       margin-left: -35%;
//       margin-top: -1%;
//       .arrow__two {
//         transform: scaleX(-1) rotate(110deg);
//         width: 15rem;
//       }
//       .text__two {
//         padding: 1.6rem 1.5rem;
//         font-size: 2rem;
//         top: -20%;
//         left: 55%;
//       }
//     }

//     .helper__text__three__container {
//       right: 12%;
//       top: 46px;
//       left: 10px;
//       .arrow__three {
//         top: -25%;
//         left: -25%;
//         transform: scaleX(-1) rotate(70deg);
//         width: 15rem;
//       }
//       .text__three {
//         padding: 1.6rem 0;
//         font-size: 2rem;
//         top: -5rem;
//       }
//     }
//   }
// }



/*ashique*/
.tap__to__continue{
  position: absolute;
  bottom: 10vh;
  right: 15%;
  color: #fff;
  font-weight: 700;
  font-size: 2rem;
  cursor: pointer;
  z-index: 9999;
}
.helper__text__two__container{
  margin-left: 10%;
  margin-top: 95px;
 animation: fadeIn 3s;


}

.helper__text__container__main {
  position: relative;
}
.helper_text_common{
  float:left;
}
img.arrow__one {
  transform: rotate(90deg);
        width: 15rem;
      animation: fadeIn 3s;

}
img.arrow__two {
  transform: scaleX(-1) rotate(
110deg
);
    width: 15rem;
          animation: fadeIn 3s;

}
img.arrow__three {
  top: -25%;
  left: -25%;
  -webkit-transform: scaleX(-1) rotate(
70deg
);
  transform: scaleX(-1) rotate(
70deg
);
  width: 15rem;
  animation: fadeIn 3s;

}

.text_common {
  float: none;
  width: 100%;
  padding: 1.6rem 10px;
  font-size: 2rem;
  background: #fff;
  text-align: center;
   animation: textAnim 3s;
}
.helper__text__three__container {
  float: right;
  margin-right: 15%;
  margin-top: 65px;
animation: fadeIn 3s;
}
.helper__text__one__container{
  margin-top: 80px;
  margin-left: 13%;
animation: fadeIn 3s;

}
@media screen and (max-device-width: 991px){
  .helper__text__one__container {
    margin-top: 70px;
    margin-left: 5%;
}

.helper__text__two__container {
  margin-left: 18%;
  margin-top: 70px;}
.helper__text__three__container {
  float: right;
  margin-right: 10%;
  margin-top: 60px;
}
.helper_text_common img{
  width:10rem;
}
}
@media screen and (max-device-width: 620px){
  .helper_text_common img{
   
    width: 10rem;
}
}
@media screen and (max-device-width: 485px){
  .helper_text_common img {
    width: 6rem;
}
.helper__text__two__container {
  margin-left: 18%;
  margin-top: 52px;
}
.helper__text__one__container {
  margin-top: 61px;
  margin-left: 10%;
}
.text_common {
  font-size: 12px;}
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  15% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes textAnim {
  0% {
    opacity: 0;
  }
  30% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}